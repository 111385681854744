<template>
  <transition
    appear
    enter-active-class="transition ease-out duration-1000"
    enter-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class=""
    leave-class="opacity-100"
    leave-to-class="opacity-0"
    mode="out-in"
  >
    <div class="mb-8">
      <ValidationObserver ref="userForm" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit()" method="post" class="grid grid-cols-1 row-gap-8">
          <div>
            <label class="register-label">First name</label>
            <ValidationProvider rules="required" vid="first_name" name="First name" v-slot="{ errors }">
              <TextInput v-model="userForm.first_name" :errors="errors"/>
            </ValidationProvider>
          </div>
          <div>
            <label class="register-label">Last name</label>
            <ValidationProvider rules="required" vid="last_name" name="Last name" v-slot="{ errors }">
              <TextInput v-model="userForm.last_name" :errors="errors" :id="'last_name'"/>
            </ValidationProvider>
          </div>
          <div>
            <label class="register-label">Email</label>
            <ValidationProvider rules="required" vid="email" name="Email" v-slot="{ errors }">
              <TextInput v-model="userForm.email" :errors="errors" :type="'email'" :id="'email'"/>
            </ValidationProvider>
          </div>
          <div>
            <label class="register-label">Phone</label>
            <ValidationProvider rules="required" vid="phone" name="Phone" v-slot="{ errors }">
              <div>
                <the-mask type="tel" v-model="userForm.phone" :mask="'(###) ###-####'" class="guestio-form-input" />
              </div>
              <p class="text-red-500 text-sm mt-3 font-light" v-if="errors.length">{{ errors[0] }}</p>
            </ValidationProvider>
          </div>
          <div>
            <label class="register-label">Password</label>
            <ValidationProvider rules="required" vid="password" name="Password" v-slot="{ errors }">
              <TextInput v-model="userForm.password" :errors="errors" :type="'password'" :id="'password'"/>
            </ValidationProvider>
          </div>
          <div>
            <label class="register-label">Confirm password</label>
            <ValidationProvider rules="required" vid="password_confirmation" name="Password confirmation" v-slot="{ errors }">
              <TextInput v-model="userForm.password_confirmation" :errors="errors" :type="'password'" :id="'password_confirmation'"/>
            </ValidationProvider>
          </div>
          <div>
            <label class="register-label">Title</label>
            <ValidationProvider rules="max:255" vid="title" name="Title" v-slot="{ errors }">
              <TextInput v-model="userForm.title" :errors="errors" :id="'title'"/>
            </ValidationProvider>
          </div>
        </form>
      </ValidationObserver>

      <div v-if="userForm.terms_accepted === false" class="mt-12 text-red-500 text-sm mt-3 font-light">
        You need to "Accept" the Terms and Conditions in order to continue.
      </div>
    </div>
  </transition>
</template>

<script>
  import { mapState, mapMutations } from "vuex";
  import {TheMask} from 'vue-the-mask'
  import TextInput from "@/components/shared/TextInput"
  import timezones from '@/mixins/timezones'
  import moment from 'moment'

  export default {
    name: "PersonalInformation",
    components: { TextInput, TheMask },
    mixins: [timezones],

    data() {
      return {
        loading: false
      };
    },

    computed: {
      ...mapState('register',{
        userForm: state => state.userForm,
      })
    },

    methods: {
      ...mapMutations({
        setEmail: "register/setEmail",
      }),

      guessTimezone() {
        let zone = moment().utcOffset()

        if (moment().isDST()) {
          zone -= 60
        }

        let timezone = this.timezones.find(timezone => timezone.timezone == Intl.DateTimeFormat().resolvedOptions().timeZone);

        if (! timezone) {
          timezone = this.timezones.find(timezone => timezone.offset == (zone / 60));
        }

        if (timezone) {
          this.userForm.timezone = timezone.timezone
        }
      },
    },

    mounted() {
      window.Event.$on('userFormErrors', errors => {
        this.$refs.userForm.setErrors(errors)
      }),

      this.guessTimezone()

      if (this.$route.query.email) {
        this.setEmail(this.$route.query.email)
      }
    },
  }
</script>
